import ApolloClient from 'apollo-client'
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context';
import { createHttpLink} from 'apollo-link-http'
import { ApolloLink } from 'apollo-link';
import {onError} from 'apollo-link-error'


import introspectionQueryResultData from './DeveloperFragmentTypes'

function getCookie(name) {
  let cookieValue = null;
  if (window.document.cookie && window.document.cookie !== '') {
    let cookies = window.document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export class DeveloperError {
  constructor(message) {
     this.message = message
     this.name = 'DeveloperError'
  }
}

export class DeveloperAPIError {
  constructor(networkError, response, message, data) {
    this.networkError = networkError
    this.response = response
    this.message = message
    this.data = data || {}
  }

  get status() {
    return this.response.status
  }

  get server() {
    return 500 <= this.status && this.status < 600
  }

  get client() {
    return 400 <= this.status && this.status < 500
  }

  toString() {
    if (this.server) {
      return 'Server Error: Please try again or if this persists please contact us'
    } else if (this.client) {
      return `Client Error: ${this.message || 'If this problem persists please contact us.'}`
    } else{
      return `${this.message || 'Unknown error. If this problem persists please contact us.'}`
    }
  }
}

const JsonHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

export const isBrowser = () => typeof window !== "undefined"

class DeveloperAPI {
  static async _fetch(url, method, headers, body) {
    let response
    try {
      response = await fetch(url, {
        method: method,
        headers: headers,
        body: body,
        credentials: 'include'
      })
    } catch (theError) {
      throw new DeveloperAPIError(
          true,
          {},
          'Request failed due to network issues, might not have internet access',
          {})
    }

    if (!response.ok) {
      const responseJSON = await response.json()
      throw new DeveloperAPIError(
          false,
          response,
          'Failed request. See `error.response`',
          responseJSON)
    }
  }

  static isAuthenticated() {
    if (isBrowser()) {
      let authValue = window.localStorage.getItem('isAuthenticated')
      if (authValue !== null) {
        return JSON.parse(authValue)
      }
    }
    return false
  }

  static async login(email, password) {
    let loginURL = `${DEVELOPER_API_URL}developer/login/`
    await DeveloperAPI._fetch(
        loginURL,
        'POST',
        JsonHeaders,
        JSON.stringify({'email': email, 'password': password}))
    window.localStorage.setItem('isAuthenticated',  JSON.stringify(true))
  }

  static async forgotPassword(email) {
    await DeveloperAPI._fetch(
        `${DEVELOPER_API_URL}developer/forgot_password/`,
        'POST',
        JsonHeaders,
        JSON.stringify({'email': email})
    )
  }

  static async resetPassword(email, token, password) {
    await DeveloperAPI._fetch(
        `${DEVELOPER_API_URL}developer/reset_password/`,
        'POST',
        JsonHeaders,
        JSON.stringify({'email': email, 'token': token, 'password': password}))
  }

  static async logout() {
    let logoutURL = `${DEVELOPER_API_URL}developer/logout/`
    try {
      const csrftoken = getCookie('csrftoken')

      if (!csrftoken) {
        throw new DeveloperError('Unable to retrieve csrftoken before request.')
      }

      const headers = Object.assign({}, JsonHeaders)
      headers['X-CSRFToken'] = csrftoken

      await DeveloperAPI._fetch(logoutURL, 'POST', headers)
    } finally {
      // Always ensure we are logged out even if there is exception
      localStorage.removeItem('isAuthenticated')
    }
  }

  static createApolloClient() {
    const fragmentMatcher = new IntrospectionFragmentMatcher({introspectionQueryResultData})
    const cache = new InMemoryCache({fragmentMatcher})
    const link = ApolloLink.from([
      onError(({graphQLErrors, networkError}) => {
        if (graphQLErrors) {
          graphQLErrors.map(({message, locations, path}) =>
              console.log(
                  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
              ),
          )
        }
        if (networkError) {
          console.log(`[Network error]: ${networkError}`)
        }
      }),
      setContext((_, {headers}) => {
        return {
          headers: {
            ...headers,
            'X-CSRFToken': getCookie('csrftoken'),
          }
        }
      }),
      createHttpLink({
        uri: `${DEVELOPER_API_URL}developer/graphql/`,
        credentials: 'include'
      })
    ])

    return new ApolloClient({
      cache,
      link
    })
  }

  static hasValidationErrors(responseData) {
    if (!responseData) {
      return false
    }

    if (responseData.__typename !== 'ErrorsType') {
      return false
    }

    if (!responseData.errors ) {
      // If there is no errors then the operation is error free
      return false
    }

    let errors = {}
    responseData.errors.map(
        (error) => errors[error.field] = `${error.field}: ${error.messages.join(',')}`)
    return errors
  }
}

export default DeveloperAPI