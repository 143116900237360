import React from 'react'

import {Button, FormGroup, InputGroup} from '@blueprintjs/core'
import {ErrorMessage, Form, Formik} from 'formik'
import {Link} from '@reach/router'
import {AuthLayout} from '../components/AuthLayout'
import {FormContainer, FormErrorContainer} from '../components/Forms'
import DeveloperAPI from '../services/DeveloperAPIClient'

const queryString = require('query-string')


const ResetPasswordComplete = () => {
  return (
      <>
        <p>Your password has been reset.</p>
        <Link to="/"><Button>Home</Button></Link>
      </>)
}


class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitSuccess: false,
      submitError: undefined
    }
    this.submit = this.submit.bind(this)
  }

  validate(values) {
    let errors = {}
    if (!values.newPassword) {
      errors.newPassword = "Password is a required field."
    }
    return errors
  }

  async submit(values, actions) {
    const parsedQueryString = queryString.parse(this.props.location.search)
    try {

      if (!parsedQueryString.token || !parsedQueryString.email) {
        this.setState({
          submitError:
              "This reset link is broken because it does not contain the needed reset code."
        })
        return
      }
      await DeveloperAPI.resetPassword(
          parsedQueryString.email,
          parsedQueryString.token,
          values.newPassword)
      this.setState({submitSuccess: true})
    } catch (theError) {
      if (theError.client) {
        const clientError = theError.data
        if (clientError.errors) {
          if (clientError.errors.email) {
            this.setState({submitError:  clientError.errors.email})
            return
          } else if(clientError.errors.password) {
            actions.setFieldError('newPassword', clientError.errors.password )
            return
          }
        }
      }
      this.setState({submitError: theError.toString()})
    } finally {
      actions.setSubmitting(false)
    }
  }

  render() {
    /**
     * Public routes need to be in both the router and in stand alone files. Otherwise, what
     * happens is that the standalone pages won't be accessible from the router or by directly
     * going to the page
     * -- Vinay 11/20/2018
     */
    return (
      <AuthLayout
        title="Bite AI - Reset Password"
        footer={
          <Link to={'/login/'} style={{marginTop: 40, color: '#AAA'}}>
            Back to Login.
          </Link>}
      >
          {this.state.submitSuccess ? <ResetPasswordComplete/> :
              <Formik
                  initialValues={{newPassword: ''}}
                  validate={this.validate}
                  onSubmit={this.submit}>
                {({isSubmitting, values, handleChange}) =>
                  <Form style={{width: 300}}>
                      <FormContainer>
                        <p>Enter a new password which is greater than 8 characters and does not
                          contain all digits</p>
                        <FormGroup label={'New Password'} labelFor={'password'}>
                          <InputGroup
                            placeholder={'New Password'}
                            large
                            type={'password'}
                            autoComplete={'password'}
                            id={'password'}
                            name={'newPassword'}
                            value={values.password}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <ErrorMessage name="newPassword" component={FormErrorContainer}/>
                        {this.state.submitError ?
                            <FormContainer>{this.state.submitError}</FormContainer> : null}
                        <Button large fill type="submit"
                                disabled={isSubmitting}>Reset Password</Button>
                      </FormContainer>
                    </Form>
                }
              </Formik>
          }
        </AuthLayout>
    )
  }
}


export default ResetPassword