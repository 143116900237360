import React from 'react'
import {Col} from './Containers'
import CenteredLayout from './CenteredLayout'

import logo from '../media/bite_ai_logo.png'

export class AuthLayout extends React.Component {
  render() {
    const {children, footer, ...rest} = this.props
    return (
      <CenteredLayout {...rest} style={{backgroundColor: '#FAFAFA'}}>
        <Col>
          <a href={'https://bite.ai'}>
            <img
              src={logo}
              alt={'Bite AI Logo'}
              width={250}
              style={{marginBottom: 50}}/>
          </a>
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: 6,
              padding: '50px 40px',
              boxShadow: '0px 0px 65px -26px rgba(0,0,0,0.37)',
              border: '1px solid #EAEAEA'
            }}>
            <Col align="center" style={{padding: 0}}>
              {children}
            </Col>
          </div>

          {footer}
        </Col>
      </CenteredLayout>
    )
  }
}
