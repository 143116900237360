import styled from 'styled-components'
import {Field} from 'formik'

export const FormContainer = styled.div`
  padding: 0;
  //align-items: center;
`
export const FormErrorContainer = styled.div`
  text-align: center;
  color: red;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: border 400ms ease-in-out, padding 400ms ease-in-out, box-shadow 600ms ease-in-out;  
`

const FormFontSpec = `
  font-family: 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  font-size: 1rem;
`
export const StyledField = styled(Field)`
  ${FormFontSpec}
  display: block;
  box-sizing: border-box;
  outline: none;
  padding: 10px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  margin: 0.5rem;
  transition: border 400ms ease-in-out, padding 400ms ease-in-out, box-shadow 600ms ease-in-out;
  // background-color: #FCFCFC;
  
  &:focus {
    box-shadow: 0 2px 16px rgba(203, 203, 203, .3);
  }
`

export const StyledLabel = styled.label`
  ${FormFontSpec}
`