import React from 'react'
import styled from 'styled-components'


export const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
  
  padding: 1rem;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: ${props => props.wrap || "inherit"};
  align-items: ${props => props.align || "inherit"};
  justify-content: ${props => props.justify || "center"};
  
  padding: 1rem;
  
  width: ${props => props.width || "auto"};
  max-width: ${props => props.maxWidth || "inherit"};

  @media (max-width: 600px) {
    padding: 0.5rem;
    //flex-basis: 100%
  }
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;  
  flex-wrap: ${props => props.wrap || "inherit"};
  align-items: ${props => props.align || "inherit"};
  justify-content: ${props => props.justify || "center"};

  padding: 1rem;
  
  width: ${props => props.width || "auto"};
  max-width: ${props => props.maxWidth || "inherit"};
  
  @media (max-width: 600px) {
    padding: 0.5rem;
    //flex-basis: 100%
  }
`
