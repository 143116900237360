import {graphql, StaticQuery} from 'gatsby'
import Helmet from 'react-helmet'
import React from 'react'
import styled from 'styled-components'


const CenteredPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`


const CenteredLayout = ({children, title, ...rest}) => (
  <StaticQuery
    query={graphql`
          query SiteMetaQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
    render={data => (
      <CenteredPage {...rest}>
        <Helmet
          htmlAttributes={{lang: 'en'}}
          meta={[{name: 'description'}]}
          title={title || data.site.siteMetadata.title}
        />
        {children}
      </CenteredPage>
    )}
  />
)

export default CenteredLayout